<template>
  <div class="w-full mb-2 pb-4 border-b border-gray-200 dark:border-gray-700">
    <div class="w-full flex flex-wrap mb-4 items-end">
      <div class="mb-auto">
        <Checkbox v-model="form.isSelected" @change="toggleItemIsSelected" />
      </div>

      <div class="mr-12 w-72">
        <Input name="description" placeholder="Nosaukums" v-model="form.description" />
      </div>

      <span class="cursor-pointer" @click="openServiceApplication">
        <ItemText title="Servisa pieprasījums" :text="`#${item.service_application.id}`" />
      </span>
    </div>

    <div class="w-full flex flex-wrap mb-4 items-end pl-12">
      <template v-if="form.service_application.closed_at">
        <ItemText title="Servisa datums" :text="form.service_application.closed_at" />
      </template>

      <ItemText title="Cena/Eur" :text="item.price" />
      <ItemText title="Daudzums" :text="form.quantity" />
      <ItemText title="Kopā EUR" :text="form.total" />

      <div class="mr-12 w-32">
        <Input type="number" name="discount" placeholder="Atlaide %" v-model="form.discount" @change="calculateTotal" />
      </div>

      <ItemText title="Summa EUR" :text="+form.total_with_discount.toFixed(2)" />

      <div class="mr-12 w-32">
        <Input type="number" name="vat_rate" placeholder="PVN %" v-model="form.vat_rate" @change="calculateTotal" />
      </div>
    </div>
  </div>
</template>

<script>
import Calculations from "@/services/Calculations/calculations";
import Checkbox from "@/components/Components/Checkbox"
import ItemText from "@/components/Components/ItemText"
import Input from "@/components/Components/Input"

export default {
  name: "InvoicebleServiceItem",
  components: {
    Checkbox,
    ItemText,
    Input,
  },
  props: ['item', 'toggleItemIsSelected', 'recalculateTotals'],
  data: () => ({
    form: null,
  }),
  created() {
    this.form = this.item
    this.calculateTotal()
  },
  methods: {
    calculateTotal() {
      this.form.total_with_discount = +Calculations.getPriceWithDiscount(this.form.total, this.form.discount)
      this.form.vat = (Calculations.getVat(this.form.total_with_discount, this.form.vat_rate)).toFixed(2)
      this.form.total_with_vat = ((this.form.total_with_discount * 1) + +this.form.vat).toFixed(2)
      this.recalculateTotals()
    },
    openServiceApplication() {
      let routeData = this.$router.resolve({path: '/service/service_applications/' + this.item.service_application.id});
      window.open(routeData.href, '_blank');
    }
  }
}
</script>

<style>

</style>